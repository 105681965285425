import { render, staticRenderFns } from "./VersionDisplay.vue?vue&type=template&id=5877d030&scoped=true&"
import script from "./VersionDisplay.vue?vue&type=script&lang=js&"
export * from "./VersionDisplay.vue?vue&type=script&lang=js&"
import style0 from "./VersionDisplay.vue?vue&type=style&index=0&id=5877d030&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5877d030",
  null
  
)

export default component.exports